<!--
 * @Description: 生成结算对账单
 * @Author: 琢磨先生
 * @Date: 2022-09-07 04:32:31
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-11-16 16:18:14
-->
<template>
  <el-button type="primary" size="small" @click="onOpen" round icon="plus"
    >生成结算对账单</el-button
  >
  <el-dialog
    title="生成结算对账单"
    v-model="visible"
    width="600px"
    draggable
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-alert
      title="生成月份结算账单、已确认了的账单将无法重新生成！"
      type="error"
      :closable="false"
      style="margin-bottom: 20px"
    ></el-alert>
    <el-form :model="form" ref="form" :rules="rules" label-width="80px">
      <el-form-item label="房间">
        <el-cascader
          :options="huxing_list"
          v-model="form.storeIds"
          style="width: 300px"
          filterable
        ></el-cascader>
        <div class="help-block">不选择房间将生成所有房间的结算数据</div>
      </el-form-item>
      <el-form-item label="月份" prop="month">
        <el-date-picker
          v-model="form.month"
          type="month"
          value-format="YYYY-MM-DD"
          placeholder
          :disabled-date="handleDisabled"
        ></el-date-picker>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      saving: false,
      form: {},
      rules: {
        month: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  props: ["huxing_list"],
  emits: ["close"],
  methods: {
    onOpen() {
      this.visible = true;
    },
    /**
     *
     */
    handleDisabled(item) {
      return item > new Date();
    },
    /**
     * 提交
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("seller/v1/landlordBill/create", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.visible = false;
                this.$emit("close", true);
              }
            })
            .finally(() => {
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style   scoped>
</style>